<template>
    <LiefengContent>
        <template v-slot:title>类型管理</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" height="200" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
            <LiefengModal :title="currentFormData.catId ? '修改' : '新增'" width="30%" height="350px" :value="addModal.status" @input="addModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="类型名称" prop="catName">
                            <Input placeholder="请输入类型名称" v-model.trim="currentFormData.catName"></Input>
                        </FormItem>
                        <FormItem label="图标">
                            <LiefengUpload
                                ref="LiefengUploadIcon"
                                v-if="showImage"
                                accept=".jpg,.png,.gif,.jpeg"
                                :format="['jpg', 'png', 'jpeg', 'gif']"
                                :defaultList="currentFormData.backgroundImage"
                                :showView="true"
                                :multiple="true"
                                :fileLength="1"
                            ></LiefengUpload>
                        </FormItem>
                        <FormItem label="排序">
                            <Input type="number" v-model.trim="currentFormData.sort" placeholder="请选择排序" maxlength="500"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="submit">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: { LiefengTable, LiefengContent, LiefengModal, LiefengUpload },
    data() {
        return {
            talbeColumns: [
                {
                    title: "类型",
                    align: "center",
                    minWidth: 180,
                    key: "catName",
                },
                {
                    title: "图标",
                    minWidth: 200,
                    key: "creatorName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    padding: "10px 0",
                                    display: params.row.backgroundImage ? "block" : "none",
                                    // justifyContent:'center'
                                    textAlign: "center",
                                },
                            },
                            [
                                h("img", {
                                    style: {
                                        width: "40px",
                                        height: "40px",
                                    },
                                    attrs: {
                                        src: params.row.backgroundImage,
                                    },
                                }),
                                h(
                                    "span",
                                    {
                                        style: {
                                            display: params.row.backgroundImage ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            marginLeft: "10px",
                                        },
                                        on: {
                                            click: async () => {
                                                this.getDetail(params.row.catId)
                                            },
                                        },
                                    },
                                    "更换"
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "排序",
                    align: "center",
                    minWidth: 180,
                    key: "sort",
                },
                {
                    title: "操作",
                    align: "center",
                    width: 200,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                try {
                                                    this.getDetail(params.row.catId)
                                                } catch (err) {
                                                    this.$Message.error({
                                                        content: "获取详情失败",
                                                        background: true,
                                                    })
                                                    return
                                                }
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.deleteData(params.row.catId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,

            currentFormData: {},
            validateForm: {
                catName: [{ required: true, message: "请输入名称", trigger: "blur" }],
                // eventDesc: [{ required: true, message: "请输入来源项目", trigger: "blur" }],
            },

            addModal: {
                status: false,
            },
            showImage: false,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getDetail(catId) {
            this.$post("/gateway/api/syscenic/pc/cat/queryByCatId", {
                catId,
            }).then(res => {
                if (res.data && res.code == 200) {
                    this.currentFormData = {
                        ...res.data,
                        backgroundImage: res.data.backgroundImage && res.data.backgroundImage != "" ? [{ url: res.data.backgroundImage, name: "" }] : [],
                    }
                    this.showImage = false
                    this.$nextTick(() => {
                        this.showImage = true
                    })
                    this.addModalData(true)
                } else {
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
        append() {
            this.currentFormData = {
                scenicId: this.$route.query.scenicId, //景区id
                catName: "", //分类名称
                catType: "1", //分类类型。可默认1
                sort: 1, //排序字段
                backgroundImage: "", //背景图
            }
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })

            this.addModalData(true)
        },
        deleteData(id) {
            this.$Modal.confirm({
                title: "删除选项",
                content: "删除后无法恢复，是否继续删除？",
                okText: "确认删除",
                cancelText: "取消",
                onOk: () => {
                    this.$post("/gateway/api/syscenic/pc/cat/deleteScenicCat", {
                        catId: id,
                    }).then(res => {
                        var that = this
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            that.getList()
                            return
                        }
                    })
                },
            })
        },
        submit() {
            this.currentFormData.backgroundImage = (() => {
                if (this.$refs.LiefengUploadIcon.uploadList && this.$refs.LiefengUploadIcon.uploadList.length) {
                    let list = []
                    this.$refs.LiefengUploadIcon.uploadList.map(item => {
                        list.push(item.url)
                    })
                    return list && list.length ? list.join(",") : ""
                } else {
                    return ""
                }
            })()
            this.$refs.form.validate(status => {
                if (status) {
                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    var url = ""
                    if (cFormData.catId && cFormData.catId != "") {
                        url = "/gateway/api/syscenic/pc/cat/updateScenicCat"
                    } else {
                        url = "/gateway/api/syscenic/pc/cat/addScenicCat"
                    }
                    this.$post(
                        url,
                        {
                            ...cFormData,
                            sort: cFormData.sort && cFormData.sort != "" ? Number(cFormData.sort) : 1,
                        },
                        { "content-type": "application/json" }
                    )
                        .then(res => {
                            this.addModalData(false)
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "操作成功",
                                })
                                this.currentFormData = {}
                                this.getList(this.page)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },
        addModalData(status) {
            this.addModal.status = status
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/cat/selectByScenic", {
                scenicId: this.$route.query.scenicId,
                catType: "1",
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.loading = false
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                        }
                    })
                }
            })
        },
    },
}
</script>

<style>
</style>